import '../style.css'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
hemiLight.position.set(0, 200, 0);
scene.add(hemiLight);

const dirLight = new THREE.DirectionalLight(0xffffff);
dirLight.position.set(0, 200, 100);
dirLight.castShadow = true;
dirLight.shadow.camera.top = 180;
dirLight.shadow.camera.bottom = - 200;
dirLight.shadow.camera.left = - 120;
dirLight.shadow.camera.right = 120;
scene.add(dirLight);

const light = new THREE.AmbientLight(0x404040); // soft white light
scene.add(light);
/**
 * Object
 */

/*const geometry = new THREE.BoxGeometry(1, 1, 1)
const material = new THREE.MeshBasicMaterial({ color: 0xFEAFA8 })
const mesh = new THREE.Mesh(geometry, material)
scene.add(mesh)*/
const loader = new GLTFLoader();

loader.load('models/totoro.glb', function (gltf) {
    console.log(gltf.scene)
    gltf.scene.scale.x = 0.05
    gltf.scene.scale.y = 0.05
    gltf.scene.scale.z = 0.05
    gltf.scene.rotation.x = (Math.PI * 0.25)
    gltf.scene.position.y = gltf.scene.position.y - 0.5
    //Mouse
    const mouse = new THREE.Vector2()

    window.addEventListener('mousemove', (m) => {
        mouse.x = (m.clientX / sizes.width) * 2 - 1
        mouse.y = (m.clientY / sizes.height) * 2 - 0.5


        gltf.scene.rotation.y = mouse.x
        gltf.scene.rotation.x = mouse.y / 3
    })
    scene.add(gltf.scene);

}, undefined, function (error) {

    console.error(error);

});

scene.background = new THREE.Color(0x8BBAD4)
/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

//Resize 

window.addEventListener('resize', () => {
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})


/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 3
scene.add(camera)

// Controls
/*const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true*/

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    //controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()